<template>
  <div class="col-xl-3 col-lg-6 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card card-custom wave wave-animate-fast wave-warning">
        <div class="card-body" @click="tagAnalytic">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="mr-6">
              <span class="svg-icon svg-icon-warning svg-icon-4x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                href="#"
                class="
                  text-dark text-hover-primary
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
                Total User
              </a>
              <div class="text-dark-75">
                <span class="text-dark-75 font-weight-bolder font-size-h3">
                  <span id="indicator_total_user">
                    {{ this.dataTotal ? this.dataTotal : 0 }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import BzbsProfile from '@/core/Account/service/BzbsProfile'

export default {
  name: 'TotalUserBox',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataTotal: 0,
      isCompany: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_total',
        'click_total_user',
        'on click',
      )
    },
    init() {
      this.getCRMPlusProfile()
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.isCompany = res.data.IsCompany
            this.getTotal()
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            this.getTotal()
            resolve(error)
          })
      })
    },
    getTotal() {
      if (this.isCompany == true) {
        BzbsDashboard.apiGetTotalCompanyUser()
          .then(res => {
            this.dataTotal = res.data.value
            this.$emit('changeLoadingBox', 1)
          })
          .catch(error => {
            console.log('apiGetTotalUser error', error)
            this.$emit('changeLoadingBox', 5)
          })
      } else {
        BzbsDashboard.apiGetTotalUser()
          .then(res => {
            this.dataTotal = res.data.value
            this.$emit('changeLoadingBox', 1)
          })
          .catch(error => {
            console.log('apiGetTotalUser error', error)
            this.$emit('changeLoadingBox', 5)
          })
      }
    },
  },
}
</script>

<style lang="sass"></style>
