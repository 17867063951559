<template>
  <div class="no-data">
    <img
      src="../../assets/images/file-search.svg"
      alt="empty data"
      class="no-data-icon"
    />
    <span class="no-data-text">No Data</span>
  </div>
</template>

<script>
export default {
  name: 'NoData',
}
</script>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  letter-spacing: 0.15px;
  color: #616161;

  &-icon {
    width: 60px;
    height: 60px;
  }
}
</style>
