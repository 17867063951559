<template>
  <div class="col-xl-3 col-lg-6 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card card-custom wave wave-animate-fast wave-success">
        <div class="card-body" @click="tagAnalytic">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="mr-6">
              <span class="svg-icon svg-icon-success svg-icon-4x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <polygon
                      fill="#000000"
                      opacity="0.3"
                      points="5 3 19 3 23 8 1 8"
                    ></polygon>
                    <polygon fill="#000000" points="23 8 12 20 1 8"></polygon>
                  </g>
                </svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                href="#"
                class="
                  text-dark text-hover-primary
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
                {{ this.Title }}
              </a>
              <div class="text-dark-75">
                <span class="text-dark-75 font-weight-bolder font-size-h3">
                  <span id="indicator_earn_stamp">
                    {{ this.dataTotal ? this.dataTotal : 0 }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import BzbsProfile from '@/core/Account/service/BzbsProfile'

export default {
  name: 'TotalEarnStamp',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataTotal: 0,
      Title: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_total',
        'click_unique_redeem',
        'on click',
      )
    },
    init() {
      this.getCRMPlusProfile()
      this.getTotal()
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            console.log('getCRMPlusProfile : ', res.data)

            if (res.data.StoreType == 'point') {
              this.Title = 'Unique Earn Point User'
            } else {
              this.Title = 'Unique Earn Stamp User'
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    getTotal() {
      BzbsDashboard.apiGetEarnStamp()
        .then(res => {
          this.dataTotal = res.data.value
          console.log('Unique Earn Point User : ', this.dataTotal)
          this.$emit('changeLoadingBox', 1)
        })
        .catch(error => {
          console.log('apiGetEarnStamp error', error)
          this.$emit('changeLoadingBox', 5)
        })
    },
  },
}
</script>

<style lang="sass"></style>
