<template>
  <div class="col-xl-3 col-lg-6 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card card-custom wave wave-animate-fast wave-primary">
        <div class="card-body" @click="tagAnalytic">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="mr-6">
              <span class="svg-icon svg-icon-primary svg-icon-4x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z"
                      fill="#000000"
                    ></path>
                    <path
                      d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z"
                      fill="#000000"
                      opacity="0.3"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                href="#"
                class="
                  text-dark text-hover-primary
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
                Daily Active User
              </a>
              <div class="text-dark-75">
                <span class="text-dark-75 font-weight-bolder font-size-h3">
                  <span id="indicator_daily_user">
                    {{ this.dataTotal ? this.dataTotal : 0 }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'

export default {
  name: 'TotalDailyActiveUser',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataTotal: 0,
    }
  },
  created() {
    this.init()
  },
  methods: {
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_total',
        'click_daily active_user',
        'on click',
      )
    },
    init() {
      this.getTotal()
    },
    getTotal() {
      BzbsDashboard.apiGetDailyUser()
        .then(res => {
          this.dataTotal = res.data.value
          this.$emit('changeLoadingBox', 1)
        })
        .catch(error => {
          console.log('apiGetDailyUser error', error)
          this.$emit('changeLoadingBox', 5)
        })
    },
  },
}
</script>

<style lang="sass"></style>
