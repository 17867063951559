<template>
  <div class="col-xl-4 col-lg-4 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b panel panel-default">
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="user" class="color-icon-user pr-2" />Member Profile: Age
        </h3>
      </div>
      <div class="card-body pt-0">
        <Nodata v-if="!dataAge || dataAge.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import Nodata from '@/components/Layout/NoData.vue'

export default {
  name: 'ChartAge',
  mixins: [Mixin],
  components: {
    Nodata,
  },
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataAge: [],
      labelsData: [],
      seriesData: [],
      options: {},
      series: [],
    }
  },
  watch: {
    data() {
      this.getChart()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChart()
    },
    getChart() {
      this.dataAge = []
      let params = {
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      BzbsDashboard.apiGetActiveAge(params)
        .then(res => {
          this.dataAge = res.data.data
          setTimeout(() => this.genChart(), 100)
        })
        .catch(error => {
          console.log('apiGetActiveAge error', error)
          setTimeout(() => this.genChart(), 100)
        })
    },
    genChart() {
      this.seriesData = []
      this.labelsData = []

      _.forEach(this.dataAge, item => {
        this.labelsData.push(item.key ? item.key : 'N/A')
        this.seriesData.push(parseInt(item.value))
      })

      this.options = {
        labels: this.labelsData,
        colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
      }
      this.series = this.seriesData

      this.$emit('changeLoading', 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
