<template>
  <div class="col-xl-6 col-lg-6 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b panel panel-default">
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="shop" theme="filled" class="color-icon-shop pr-2" />
          Branch / Terminal Activity (Give Point and Give Reward)
        </h3>
      </div>
      <div class="card-body pt-0">
        <Nodata v-if="!dataBranchActivity || dataBranchActivity.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import Nodata from '@/components/Layout/NoData.vue'
import Locale from '@/helper/locale.js'

export default {
  name: 'ChartBranchActivity',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  components: {
    Nodata,
  },
  data() {
    return {
      dataBranchActivity: [],
      seriesData: [],
      options: {},
      series: [],
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    data: function () {
      this.getChart()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChart()
    },
    getChart() {
      this.dataBranchActivity = []
      let params = {
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      BzbsDashboard.apiGetBranch(params)
        .then(res => {
          this.dataBranchActivity = res.data.Detail
          setTimeout(() => this.genChart(), 100)
        })
        .catch(error => {
          console.log('apiGetBranch error', error)
          setTimeout(() => this.genChart(), 100)
        })
    },
    genChart() {
      this.seriesData = []

      _.forEach(this.dataBranchActivity, item => {
        this.seriesData.push({
          name: item.BranchName,
          data: [{ x: '', y: item.NumberOfUser }],
        })
      })

      this.options = {
        chart: {
          id: 'vuechart-branchactivity',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
          toolbar: {
            export: {
              csv: {
                filename: 'branch_terminal_activity',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: 'branch_terminal_activity',
              },
              png: {
                filename: 'branch_terminal_activity',
              },
            },
          },
        },
        colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          title: {
            text: 'Number of User',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily:
                this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            s̶t̶a̶r̶t̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
            e̶n̶d̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
            borderRadius: 15,
            columnWidth: '70%',
            barHeight: '70%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
            dataLabels: {
              position: 'top',
              maxItems: 100,
              hideOverflowingLabels: false,
            },
          },
        },
      }
      this.series = this.seriesData

      this.$emit('changeLoading', 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
