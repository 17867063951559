<template>
  <div class="col-xl-6 col-lg-6 col-md-6 pb-4">
    <div
      class="card card-custom card-stretch gutter-b panel panel-default"
      @click="tagAnalytic"
    >
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="trophy" theme="filled" class="color-icon-trophy pr-2" />
          Campaign / Reward redemption (Top 5)
        </h3>
      </div>
      <div class="card-body pt-0">
        <Nodata v-if="!dataCampaigns || dataCampaigns.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import Nodata from '@/components/Layout/NoData.vue'
import Locale from '@/helper/locale.js'

export default {
  name: 'ChartCampaigns',
  mixins: [Mixin],
  components: {
    Nodata,
  },
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataCampaigns: [],
      seriesData: [],
      options: {},
      series: [],
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    data() {
      this.getChart()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChart()
      console.log('currentLocale : ', this.currentLocale)
    },
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_campaign',
        'click_campaign_download',
        'on click',
      )
    },
    getChart() {
      this.dataCampaigns = []
      let params = {
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      BzbsDashboard.apiGetCampaign(params)
        .then(res => {
          this.dataCampaigns = res.data.data
          setTimeout(() => this.genChart(), 100)
        })
        .catch(error => {
          console.log('apiGetCampaign error', error)
          setTimeout(() => this.genChart(), 100)
        })
    },
    genChart() {
      this.seriesData = []

      _.forEach(this.dataCampaigns, item => {
        this.seriesData.push({
          name: item.name,
          data: [{ x: '', y: item.value }],
        })
      })

      this.options = {
        chart: {
          id: 'vuechart-campaigns',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
          toolbar: {
            export: {
              csv: {
                filename: 'campaign_reward_redemption_top5',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: 'campaign_reward_redemption_top5',
              },
              png: {
                filename: 'campaign_reward_redemption_top5',
              },
            },
          },
        },
        colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          title: {
            text: 'Number of User',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily:
                this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            s̶t̶a̶r̶t̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
            e̶n̶d̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
            borderRadius: 15,
            columnWidth: '70%',
            barHeight: '70%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
            dataLabels: {
              position: 'top',
              maxItems: 100,
              hideOverflowingLabels: false,
            },
          },
        },
      }
      this.series = this.seriesData

      this.$emit('changeLoading', 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
