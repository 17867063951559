<template>
  <div class="col-xl-4 col-lg-4 col-md-6 pb-4">
    <div
      class="card card-custom card-stretch gutter-b panel panel-default"
      @click="tagAnalytic"
    >
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="sketch" class="color-icon-sketch pr-2" />
          {{ this.Title }}
        </h3>
      </div>
      <div class="card-body pt-0">
        <Nodata v-if="!dataEarnStamp || dataEarnStamp.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="line"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import moment from 'moment'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Nodata from '@/components/Layout/NoData.vue'
import Locale from '@/helper/locale.js'

export default {
  name: 'ChartEarnStamp',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  components: {
    Nodata,
  },
  data() {
    return {
      dataEarnStamp: [],
      seriesData: [],
      options: {},
      series: [],
      Title: 'Earn Point',
      StoreType: 'Number of Earn Point User',
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    data() {
      this.getChart()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getCRMPlusProfile()
      this.getChart()
    },
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_earn',
        'click_earn_download',
        'on click',
      )
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            console.log('getCRMPlusProfile : ', res.data)
            this.StoreType = res.data.StoreType
            if (res.data.StoreType == 'stamp') {
              this.Title = 'Earn Stamp'
            } else {
              this.Title = 'Earn Point'
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    getChart() {
      this.dataEarnStamp = []
      let params = {
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      BzbsDashboard.apiGetChartEarnStamp(params)
        .then(res => {
          this.dataEarnStamp = res.data.data
          console.log('dataEarnStamp :', this.dataEarnStamp)
          setTimeout(() => this.genChart(), 100)
        })
        .catch(error => {
          console.log('apiGetChartEarnStamp error', error)
          setTimeout(() => this.genChart(), 100)
        })
    },
    genChart() {
      this.seriesData = []
      let dataUsers = []
      let title = ''

      if (this.StoreType == 'stamp') {
        title = 'Number of Earn Stamp User'
      } else {
        title = 'Number of Earn Point User'
      }
      this.dataEarnStamp.sort((a, b) =>
        moment(a.date).isBefore(moment(b.date)) ? -1 : 1,
      )
      _.forEach(this.dataEarnStamp, item => {
        let date = moment(item.date).format('MMM DD')
        dataUsers.push({ x: date, y: item.value })
      })

      this.seriesData.push({ name: 'Users', data: dataUsers })
      var file_name
      if (this.StoreType == 'stamp') {
        file_name = 'earn_stamp'
      } else {
        file_name = 'earn_point'
      }
      this.options = {
        chart: {
          id: 'vuechart-earnstamp',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
          toolbar: {
            export: {
              csv: {
                filename: file_name,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: file_name,
              },
              png: {
                filename: file_name,
              },
            },
          },
        },
        colors: ['rgb(52, 73, 94)'],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on',
          title: {
            text: title,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily:
                this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
          },
        },
      }
      this.series = this.seriesData

      this.$emit('changeLoading', 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
