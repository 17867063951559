<template>
  <div class="col-xl-4 col-lg-4 col-md-6 pb-4">
    <div
      class="card card-custom card-stretch gutter-b panel panel-default"
      @click="tagAnalytic"
    >
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="user-add" class="color-icon-user-add pr-2" />New Users
        </h3>
      </div>
      <div class="card-body pt-0">
        <Nodata v-if="!dataNewUsers || dataNewUsers.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="line"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import moment from 'moment'
import Nodata from '@/components/Layout/NoData.vue'
import Locale from '@/helper/locale.js'

export default {
  name: 'ChartNewUsers',
  mixins: [Mixin],
  components: {
    Nodata,
  },
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataNewUsers: [],
      seriesData: [],
      options: {},
      series: [],
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    data() {
      this.getChart()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChart()
    },
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_new_user',
        'click_new_user_download',
        'on click',
      )
    },
    getChart() {
      this.dataNewUsers = []
      let params = {
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      BzbsDashboard.apiGetNewUser(params)
        .then(res => {
          this.dataNewUsers = res.data.data
          if (this.dataNewUsers && this.dataNewUsers.length > 0) {
            setTimeout(() => this.genChart(), 100)
          }
        })
        .catch(error => {
          console.log('apiGetNewUser error', error)
          setTimeout(() => this.genChart(), 100)
        })
    },
    genChart() {
      this.seriesData = []
      let dataUsers = []

      if (this.dataNewUsers && this.dataNewUsers.length > 0) {
        let locale = Locale.getLocaleCode()
        if (locale != 'th') locale = 'en'
        moment.locale(locale)

        this.dataNewUsers.sort((a, b) =>
          moment.unix(a.date).isBefore(moment.unix(b.date)) ? -1 : 1,
        )
        _.forEach(this.dataNewUsers, item => {
          let date = moment.unix(item.date).format('MMM DD')
          dataUsers.push({ x: date, y: item.value })
        })

        this.seriesData.push({ name: 'Users', data: dataUsers })

        if (this.seriesData.length > 0) {
          this.options = {
            chart: {
              id: 'vuechart-new-users',
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
              background: '#fff',
              fontFamily:
                this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
              foreColor: '#000',
              toolbar: {
                export: {
                  csv: {
                    filename: 'new_users',
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString()
                    },
                  },
                  svg: {
                    filename: 'new_users',
                  },
                  png: {
                    filename: 'new_users',
                  },
                },
              },
            },
            colors: ['rgb(52, 73, 94)'],
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              type: 'category',
              tickPlacement: 'on',
              title: {
                text: 'Number of new user',
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: '12px',
                  fontFamily:
                    this.currentLocale == 'th'
                      ? 'AnuphanRegular'
                      : 'RobotoRegular',
                  fontWeight: 600,
                  cssClass: 'apexcharts-xaxis-title',
                },
              },
            },
          }
          this.series = this.seriesData

          this.$emit('changeLoading', 1)
        }
      } else {
        this.$emit('changeLoading', 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
