<template>
  <div class="col-xl-12 col-lg-12 col-md-12 pb-4 Chart-ActiveUsers">
    <div class="card card-custom card-stretch gutter-b panel panel-default">
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          <a-icon type="carry-out" class="color-icon-carry pl-1 pr-1" />
          <span class="pl-1">Monthly Active Users</span>
        </h3>
        <div class="card-toolbar">
          <a-form-item class="field-item">
            <a-select
              v-decorator="['year_id']"
              size="large"
              :default-value="opYear[0].name"
              @change="
                e => {
                  changeFunction(e)
                }
              "
            >
              <a-spin slot="notFoundContent" size="small" />
              <a-select-option
                v-for="(item, index) in opYear"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="card-body pt-0">
        <h6 class="font-weight-bolder text-dark mb-0 ml-3">
          Number of unique user access the platform including New User
        </h6>
        <Nodata v-if="!dataActive || dataActive.length == 0" />
        <apexchart
          v-else
          width="100%"
          height="400px"
          type="bar"
          :options="options"
          :series="series"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import Nodata from '@/components/Layout/NoData.vue'
import Locale from '@/helper/locale.js'

export default {
  name: 'chartActiveUsers',
  mixins: [Mixin],
  components: {
    Nodata,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      dataActive: [],
      categoriesData: [],
      seriesData: [],
      options: {},
      series: [],
      opYear: [],
      dataYear: new Date().getFullYear(),
      currentLocale: Locale.getLocaleShort(),
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'chartactiveusers',
    })
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getYearList()
      this.getChart()
    },
    getYearList() {
      let data = [
        { id: new Date().getFullYear(), name: new Date().getFullYear() },
        {
          id: new Date().getFullYear() - 1,
          name: new Date().getFullYear() - 1,
        },
        {
          id: new Date().getFullYear() - 2,
          name: new Date().getFullYear() - 2,
        },
      ]

      this.opYear = data
    },
    changeFunction(e) {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_monthly',
        'click_fillter_year',
        'on click',
      )
      this.dataYear = e
      this.getChart()
    },
    getChart() {
      this.dataActive = []
      var count = 0
      for (let i = 1; i <= 12; i++) {
        let params = {
          year: this.dataYear,
          month: i,
        }
        BzbsDashboard.apiGetMonthlyActive(params)
          .then(res => {
            if (res.data.data.length > 0) {
              this.dataActive.push({ month: i, data: res.data.data })

              this.dataActive.sort(function (a, b) {
                if (a.month < b.month) {
                  return -1
                }
                if (a.month > b.month) {
                  return 1
                }
                return 0
              })
              count++
            }
            if (count == this.dataActive.length) {
              setTimeout(() => this.genChart(), 200)
            }
          })
          .catch(error => {
            console.log('apiGetMonthlyActive error', error)
            if (count == this.dataActive.length) {
              setTimeout(() => this.genChart(), 200)
            }
          })
      }
    },
    genChart() {
      this.categoriesData = []
      this.seriesData = []
      let Month = [
        { month: 1, name: 'Jan' },
        { month: 2, name: 'Feb' },
        { month: 3, name: 'Mar' },
        { month: 4, name: 'Apr' },
        { month: 5, name: 'May' },
        { month: 6, name: 'Jun' },
        { month: 7, name: 'Jul' },
        { month: 8, name: 'Aug' },
        { month: 9, name: 'Sep' },
        { month: 10, name: 'Oct' },
        { month: 11, name: 'Nov' },
        { month: 12, name: 'Dec' },
      ]

      if (this.dataActive.length > 0) {
        _.forEach(this.dataActive, item => {
          _.forEach(Month, month => {
            if (item.month == month.month) {
              this.categoriesData.push(month.name)
              this.seriesData.push(
                item.data[item.data.length - 1].number_of_user,
              )
            }
          })
        })

        if (this.categoriesData.length > 0 && this.seriesData.length > 0) {
          this.options = {
            chart: {
              id: 'vuechart-monthly-active-users',
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
              background: '#fff',
              fontFamily:
                this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
              foreColor: '#000',
              toolbar: {
                export: {
                  csv: {
                    filename: 'monthly_active_users',
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    dateFormatter(timestamp) {
                      return new Date(timestamp).toDateString()
                    },
                  },
                  svg: {
                    filename: 'monthly_active_users',
                  },
                  png: {
                    filename: 'monthly_active_users',
                  },
                },
              },
            },
            colors: ['rgb(52, 73, 94)'],
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: this.categoriesData,
              title: {
                text: 'Number of Active Users',
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: '12px',
                  fontFamily:
                    this.currentLocale == 'th'
                      ? 'AnuphanRegular'
                      : 'RobotoRegular',
                  fontWeight: 600,
                  cssClass: 'apexcharts-xaxis-title',
                },
              },
            },
          }
          this.series = [
            {
              name: 'Users',
              data: this.seriesData,
            },
          ]

          this.$emit('changeLoadingBox', 1)
        }
      } else {
        this.$emit('changeLoadingBox', 5)
      }
    },
  },
}
</script>

<style lang="scss">
.Chart-ActiveUsers {
  .ant-select-lg {
    font-size: 16px;
    width: 100px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
}
</style>
<style lang="scss" scoped>
.card-body {
  z-index: 1;
}
</style>
