<template>
  <div class="col-xl-3 col-lg-6 col-md-6 pb-4">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card card-custom wave wave-animate-fast wave-danger">
        <div class="card-body" @click="tagAnalytic">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="mr-6">
              <span class="svg-icon svg-icon-danger svg-icon-4x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M14,7 C13.6666667,10.3333333 12.6666667,12.1167764 11,12.3503292 C11,12.3503292 12.5,6.5 10.5,3.5 C10.5,3.5 10.287918,6.71444735 8.14498739,10.5717225 C7.14049032,12.3798172 6,13.5986793 6,16 C6,19.428689 9.51143904,21.2006583 12.0057195,21.2006583 C14.5,21.2006583 18,20.0006172 18,15.8004732 C18,14.0733981 16.6666667,11.1399071 14,7 Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <div class="d-flex flex-column">
              <a
                href="#"
                class="
                  text-dark text-hover-primary
                  font-weight-bold font-size-h4
                  mb-3
                "
              >
                Unique Redeem User
              </a>
              <div class="text-dark-75">
                <span class="text-dark-75 font-weight-bolder font-size-h3">
                  <span id="indicator_redemption">
                    {{ this.dataTotal ? this.dataTotal : 0 }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'

export default {
  name: 'TotalRedemption',
  mixins: [Mixin],
  props: {
    data: Object,
    form: Object,
  },
  data() {
    return {
      dataTotal: 0,
    }
  },
  created() {
    this.init()
  },
  methods: {
    tagAnalytic() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_total',
        'click_unique_redeem',
        'on click',
      )
    },
    init() {
      this.getTotal()
    },
    getTotal() {
      BzbsDashboard.apiGetRedemption()
        .then(res => {
          this.dataTotal = res.data.value
          this.$emit('changeLoadingBox', 1)
        })
        .catch(error => {
          console.log('apiGetRedemption error', error)
          this.$emit('changeLoadingBox', 5)
        })
    },
  },
}
</script>

<style lang="sass"></style>
