<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrapper-ifrem">
          <div class="row">
            <!-- Dashboard 1-->
            <div class="col-xl-12 col-lg-12 col-md-12 pb-4 wrapper-dashboard">
              <div
                class="card card-custom card-stretch gutter-b panel panel-default"
              >
                <div class="card-header border-0">
                  <div
                    class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
                  >
                    <!-- Insights / Transactions -->
                    <div class="d-flex align-items-center flex-wrap mr-2">
                      <span class="svg-icon svg-icon-warning svg-icon-2x"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="4"
                              y="4"
                              width="4"
                              height="4"
                              rx="1"
                            ></rect>
                            <path
                              d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z"
                              fill="#000000"
                            ></path>
                          </g></svg
                      ></span>
                      <h5
                        class="text-dark font-weight-bold mt-2 mb-2 mr-5 mb-1 mt-1"
                      >
                        Dashboard
                      </h5>
                    </div>
                    <div v-show="alertPackage" class="alert-package">
                      <a-icon
                        class="pt-0 mr-3"
                        type="clock-circle"
                      />ใช้งานได้อีก 3 วัน
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TotalUserBox @changeLoadingBox="ChangeLoadingBox($event)" />
            <DailyActiveUserBox @changeLoadingBox="ChangeLoadingBox($event)" />
            <RedemptionBox @changeLoadingBox="ChangeLoadingBox($event)" />
            <EarnStampBox @changeLoadingBox="ChangeLoadingBox($event)" />
            <MonthlyActiveUsersChart
              @changeLoadingBox="ChangeLoadingBox($event)"
            />

            <!-- Dashboard 2-->
            <div class="col-xl-12 col-lg-12 col-md-12 pb-4 wrapper-dashboard">
              <div
                class="card card-custom card-stretch gutter-b panel panel-default"
              >
                <div class="card-header border-0">
                  <div
                    class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
                  >
                    <!-- Insights / Transactions -->
                    <div class="d-flex align-items-center flex-wrap mr-2">
                      <span class="svg-icon svg-icon-warning svg-icon-2x"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="4"
                              y="4"
                              width="4"
                              height="4"
                              rx="1"
                            ></rect>
                            <path
                              d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z"
                              fill="#000000"
                            ></path>
                          </g></svg
                      ></span>
                      <h5
                        class="text-dark font-weight-bold mt-2 mb-2 mr-5 mb-1 mt-1"
                      >
                        Dashboard
                      </h5>
                      <div
                        class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
                      ></div>
                      <span class="text-muted font-weight-bold mr-4"></span>

                      <!-- See more -->
                      <button
                        v-if="!isSeemore"
                        class="primarybtn mr-3"
                        @click="handleSeemore"
                      >
                        See more
                      </button>

                      <!-- Insights -->
                      <a
                        v-if="isSeemore"
                        class="btn btn-danger mr-3 mb-1 mt-1"
                        id="bntInsight"
                        data-toggle="modal"
                        data-target="#insightModal"
                        @click="handleInsights"
                      >
                        <span class="svg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12.7442084,3.27882877 L19.2473374,6.9949025 C19.7146999,7.26196679 20.003129,7.75898194 20.003129,8.29726722 L20.003129,15.7027328 C20.003129,16.2410181 19.7146999,16.7380332 19.2473374,17.0050975 L12.7442084,20.7211712 C12.2830594,20.9846849 11.7169406,20.9846849 11.2557916,20.7211712 L4.75266256,17.0050975 C4.28530007,16.7380332 3.99687097,16.2410181 3.99687097,15.7027328 L3.99687097,8.29726722 C3.99687097,7.75898194 4.28530007,7.26196679 4.75266256,6.9949025 L11.2557916,3.27882877 C11.7169406,3.01531506 12.2830594,3.01531506 12.7442084,3.27882877 Z M12,14.5 C13.3807119,14.5 14.5,13.3807119 14.5,12 C14.5,10.6192881 13.3807119,9.5 12,9.5 C10.6192881,9.5 9.5,10.6192881 9.5,12 C9.5,13.3807119 10.6192881,14.5 12,14.5 Z"
                                fill="#000000"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        Insights
                      </a>

                      <!-- Transactions -->
                      <a
                        v-if="isSeemore"
                        href="#"
                        class="btn btn-primary mr-3 mb-1 mt-1"
                        id="bntTransaction"
                        data-toggle="modal"
                        data-target="#transactionModal"
                        @click="handleTransactions"
                      >
                        <span class="svg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <path
                                d="M4.85714286,1 L11.7364114,1 C12.0910962,1 12.4343066,1.12568431 12.7051108,1.35473959 L17.4686994,5.3839416 C17.8056532,5.66894833 18,6.08787823 18,6.52920201 L18,19.0833333 C18,20.8738751 17.9795521,21 16.1428571,21 L4.85714286,21 C3.02044787,21 3,20.8738751 3,19.0833333 L3,2.91666667 C3,1.12612489 3.02044787,1 4.85714286,1 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M6.85714286,3 L14.7364114,3 C15.0910962,3 15.4343066,3.12568431 15.7051108,3.35473959 L20.4686994,7.3839416 C20.8056532,7.66894833 21,8.08787823 21,8.52920201 L21,21.0833333 C21,22.8738751 20.9795521,23 19.1428571,23 L6.85714286,23 C5.02044787,23 5,22.8738751 5,21.0833333 L5,4.91666667 C5,3.12612489 5.02044787,3 6.85714286,3 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              ></path>
                            </g>
                          </svg> </span
                        >Transactions</a
                      >
                    </div>
                    <!-- Time -->
                    <div
                      v-if="isSeemore"
                      class="d-flex align-items-center mb-1 mt-1"
                    >
                      <a-form-item class="field-item d-flex">
                        <a-select
                          v-decorator="['date_id']"
                          size="large"
                          :default-value="
                            selectDate.length > 0 ? selectDate[3].name : []
                          "
                          @change="
                            e => {
                              changeSelectDate(e)
                            }
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />
                          <a-select-option
                            v-for="(item, index) in selectDate"
                            :key="index"
                            :value="item.id"
                          >
                            <span class="font-weight-bold">{{
                              item.name
                            }}</span>
                          </a-select-option>
                        </a-select>
                        <span
                          v-if="!isSelectDate"
                          class="font-weight-bold font-color-date"
                        >
                          {{ this.textStartDate }} - {{ this.textEndDate }}
                        </span>

                        <span
                          v-if="isSelectDate"
                          class="font-weight-bold font-color-date icon-clear"
                        >
                          <a-range-picker
                            :format="'YYYY/MM/DD'"
                            @change="
                              e => {
                                onChangePicker(e)
                              }
                            "
                          />
                        </span>
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <NewUsersChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <EarnStampChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <BurnChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <MaleFemaleChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <AgeChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <FrequencyMonthChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <CampaignsChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
            <BranchActivityChart
              v-if="isSeemore"
              :data="dataChart"
              @changeLoading="ChangeLoading($event)"
            />
          </div>

          <Insights
            :show="showModalInsights"
            :data="dataInsights"
            @changeInsights="ChangeInsights($event)"
          />
          <TransactionsEDC
            :show="showModalTransactions"
            :data="dataTransactions"
            @changeTransactions="ChangeTransactions($event)"
          />
        </div>
      </div>
    </div>
    <!-- Show Modal 1 -->
    <CookieAccept />

    <!-- Show Modal 2 -->
    <ExpirePackage
      :isOpenModal="expirePackage.isOpenModal"
      :modalType="expirePackage.modalType"
      :title="expirePackage.title"
      :details="expirePackage.details"
      :showbackto="expirePackage.showbackto"
      :backto="expirePackage.backto"
      :confirm="expirePackage.confirm"
      :onConfirm="expirePackage.onConfirm"
      :onCancel="expirePackage.onCancel"
      :fontSize="'20'"
    />

    <!-- Show Modal 3 (isWelcomeBack == true) -->
    <WelcomeBack
      :isOpenModal="welcomeBack.isOpenModal"
      :title="welcomeBack.title"
      :details="welcomeBack.details"
      :onCancel="welcomeBack.onCancel"
      :fontSize="'20'"
    />

    <!-- Show Modal 4 (isChangePackage == true) -->
    <PackageChanged
      :isOpenModal="packageChanged.isOpenModal"
      :title="packageChanged.title"
      :details="packageChanged.details"
      :lifeTime="packageChanged.lifeTime"
      :payment="packageChanged.payment"
      :imgHistory="packageChanged.imgHistory"
      :imgCurrent="packageChanged.imgCurrent"
      :onCancel="packageChanged.onCancel"
      :onView="packageChanged.onView"
      :fontSize="'20'"
    />

    <!-- Show Modal 5 -->
    <Notification
      :isOpenModal="notificationAccountSetting.isOpenModal"
      :modalType="notificationAccountSetting.modalType"
      :title="notificationAccountSetting.title"
      :details="notificationAccountSetting.details"
      :backto="notificationAccountSetting.backto"
      :confirm="notificationAccountSetting.confirm"
      :onConfirm="notificationAccountSetting.onConfirm"
      :onCancel="notificationAccountSetting.onCancel"
      @passNotify="passNotify($event)"
    />

    <!-- Show Modal 6 -->
    <Announcement
      :isOpenModal="announcement.isOpenModal"
      :data="announcement.data"
      :onCancel="announcement.onCancel"
      :fontSize="'20'"
      @onCancelAnnouncement="onCancelAnnouncement($event)"
    />
    <alertUpgradePackage
      :visible="showAlertUpgradepackage"
      :title="lbl['alert-upgrade-package-text']"
      :internal="actionUpgrade.internalPage"
      :page-name="actionUpgrade.pageName"
      @close="showAlertUpgradepackage = !showAlertUpgradepackage"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import moment from 'moment'
import TotalUserBox from '@/module/components/dashboard/box/TotalUser'
import DailyActiveUserBox from '@/module/components/dashboard/box/DailyActiveUser'
import RedemptionBox from '@/module/components/dashboard/box/Redemption'
import EarnStampBox from '@/module/components/dashboard/box/EarnStamp'
import MonthlyActiveUsersChart from '@/module/components/dashboard/chart/bar/MonthlyActiveUsers'
import NewUsersChart from '@/module/components/dashboard/chart/line/NewUsers'
import EarnStampChart from '@/module/components/dashboard/chart/line/EarnStamp'
import BurnChart from '@/module/components/dashboard/chart/line/Burn'
import MaleFemaleChart from '@/module/components/dashboard/chart/donut/MaleFemale'
import AgeChart from '@/module/components/dashboard/chart/donut/Age'
import FrequencyMonthChart from '@/module/components/dashboard/chart/donut/FrequencyMonth'
import CampaignsChart from '@/module/components/dashboard/chart/bar/Campaigns'
import BranchActivityChart from '@/module/components/dashboard/chart/bar/BranchActivity'
import Insights from '@/module/components/dashboard/popup/Insights'
import TransactionsEDC from '@/module/components/dashboard/popup/TransactionsEDC'
import Header from '@/components/Layout/Header'
import CookieAccept from '@/components/Layout/CookieAccept'
import Notification from '@/components/Modal/Component/confirmmodal2'
import ExpirePackage from '@/components/Modal/Component/confirmmodal2'
import WelcomeBack from '@/components/Modal/Component/WelcomeBack'
import PackageChanged from '@/components/Modal/Component/PackageChanged'
import alertUpgradePackage from '@/components/Modal/Component/alertUpgradePackage'
import Announcement from '@/components/Modal/Component/Announcement'
import BzbsPdpa from '@/core/Settings/BzbsSettingsPdpa'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import BzbsAnnouncement from '@/core/Announcement/service/BzbsAnnouncement'
import BzbsCart from '@/core/Cart/service/BzbsCart'
import PackageHelper from '@/helper/PackageHelper.js'

export default {
  name: 'importUserSearch',
  components: {
    TotalUserBox,
    DailyActiveUserBox,
    RedemptionBox,
    EarnStampBox,
    MonthlyActiveUsersChart,
    NewUsersChart,
    EarnStampChart,
    BurnChart,
    MaleFemaleChart,
    AgeChart,
    FrequencyMonthChart,
    CampaignsChart,
    BranchActivityChart,
    Insights,
    TransactionsEDC,
    Header,
    CookieAccept,
    Notification,
    ExpirePackage,
    WelcomeBack,
    Announcement,
    alertUpgradePackage,
    PackageChanged,
  },
  mixins: [Mixin],
  data: function () {
    return {
      isDashboard: true,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataChartMonthlyActiveUsers: null,
      dataChartNewUsers: null,
      dataChartEarnStamp: null,
      dataChartBurn: null,
      dataChartMaleFemale: null,
      dataChartAge: null,
      dataCharFrequencyMonth: null,
      dataCharCampaigns: null,
      dataCharBranchActivity: null,
      startDate: null,
      endDate: null,
      selectDate: [],
      textStartDate: '',
      textEndDate: '',
      isSelectDate: false,
      countLoading: 0,
      countLoadingBox: 0,
      isSeemore: false,
      alertPackage: false,

      //Insight
      showModalInsights: false,
      dataInsights: {},

      showModalTransactions: false,
      dataTransactions: {},
      window: window,
      notificationAccountSetting: {
        title: '',
        details: '',
        modalType: '',
        backto: '',
        confirm: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      expirePackage: {
        title: '',
        details: '',
        modalType: '',
        backto: '',
        confirm: '',
        showbackto: true,
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      welcomeBack: {
        title: '',
        details: '',
        onCancel: null,
        isOpenModal: false,
      },
      packageChanged: {
        title: '',
        details: '',
        lifeTime: '',
        payment: '',
        imgHistory: null,
        imgCurrent: null,
        onCancel: null,
        onView: null,
        isOpenModal: false,
      },
      announcement: {
        data: [],
        onCancel: null,
        isOpenModal: false,
      },
      crmPackage: {},
      profileConfig: {
        ShownAnnouncement: false,
      },
      nonStandardPackageList: [],
      subscriptionId: null,
      skip: 0,
      top: 10,
      showAlertUpgradepackage: false,
      actionUpgrade: {
        internalPage: true,
        pageName: 'Dashboard',
      },
    }
  },
  created() {
    Account.getSubscriptionInfo().then(info => {
      if (info.length > 0) {
        if (info[0].SubscriptionId) {
          this.subscriptionId = info[0].SubscriptionId
        } else {
          this.subscriptionId = null
        }
      } else {
        this.subscriptionId = null
      }
    })
    var businessName = ''
    if (Account.getCacheProfileAddress().strBusinessName) {
      businessName = Account.getCacheProfileAddress().strBusinessName
    } else if (Account.getCacheUser().BusinessName) {
      businessName = Account.getCacheUser().BusinessName
    }

    let configSelfOnBoard = false
    try {
      let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
      if (parse) {
        configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
          ? parse.buzzebeesConfigSelfOnBoard
          : false
      } else {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }
    } catch (e) {
      configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
    }

    if (configSelfOnBoard) {
      Account.apiAccountInformation().then(res => {
        console.log('res:: ', res)
        if (res.data.RequirePackage) {
          if (this.$route.query.changepackage) {
            console.log(
              'res.data.PackageChange == null: ',
              res.data.PackageChange == null,
            )
            console.log(
              'res IsPackageChangeAcknowledged:2: ',
              res.data.PackageChange,
            )

            if (
              res.data.PackageChange == null ||
              res.data.PackageChange.IsPackageChangeAcknowledged
            ) {
              Account.apiAccountInformation()
              this.crmPackage = Account.getCachePackage()
              this.checkPackageChange()
            }
            Account.apiAccountPermissionChange(res.data)
          }

          if (res.data.IsExtendExpired) {
            if (!businessName) {
              if (!Vue.bzbsConfig.businessRule.webFor.PH) {
                this.$router.push({
                  name: 'Package',
                  query: { returnPath: 'AccountSetting2' },
                })
              } else {
                this.$router.push({
                  name: 'Package',
                  query: { returnPath: 'AccountSetting' },
                })
              }
            } else {
              if (this.$route.query.changepackage) {
                this.$router
                  .push({
                    name: 'Dashboard',
                    query: {
                      changepackage: true,
                    },
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              } else {
                this.$router
                  .push({
                    name: 'Dashboard',
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              }
            }
          } else {
            if (!businessName) {
              if (!Vue.bzbsConfig.businessRule.webFor.PH) {
                this.$router.push({ name: 'AccountSetting2' })
              } else {
                this.$router.push({ name: 'AccountSetting' })
              }
            } else {
              if (this.$route.query.changepackage) {
                this.$router
                  .push({
                    name: 'Dashboard',
                    query: {
                      changepackage: true,
                    },
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              } else {
                this.$router
                  .push({
                    name: 'Dashboard',
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              }
            }
          }
        } else {
          if (this.$route.query.changepackage) {
            console.log(
              'res.data.PackageChange == null: ',
              res.data.PackageChange == null,
            )
            console.log(
              'res IsPackageChangeAcknowledged:2: ',
              res.data.PackageChange,
            )

            if (
              res.data.PackageChange == null ||
              res.data.PackageChange.IsPackageChangeAcknowledged
            ) {
              Account.apiAccountInformation()
              this.crmPackage = Account.getCachePackage()
              this.checkPackageChange()
            }
            Account.apiAccountPermissionChange(res.data)
          }

          if (!businessName) {
            if (!Vue.bzbsConfig.businessRule.webFor.PH) {
              this.$router.push({ name: 'AccountSetting2' })
            } else {
              this.$router.push({ name: 'AccountSetting' })
            }
          } else {
            if (this.$route.query.changepackage) {
              this.$router
                .push({
                  name: 'Dashboard',
                  query: {
                    changepackage: true,
                  },
                })
                .catch(err => {
                  console.log('router err', err)
                })
            } else {
              this.$router
                .push({
                  name: 'Dashboard',
                })
                .catch(err => {
                  console.log('router err', err)
                })
            }
          }
        }
        Account.bzbsAnalyticTracking(
          'home_page',
          'home',
          'view_home',
          'on view',
        )
        this.init()
        this.countLoading = 0
        this.countLoadingBox = 0
      })
    } else {
      this.init()
      this.countLoading = 0
      this.countLoadingBox = 0
    }
  },
  methods: {
    moment,
    test() {
      console.log('alert :: ', alert)
    },
    init() {
      this.handleDashboard(true)
      this.handleLoading(true)
      this.handleFooter(true)
      this.getDateList()
      this.crmPackage = Account.getCachePackage()
      this.profileConfig = Account.getCacheProfileConfig()

      console.log('profileConfig : ', this.profileConfig)
      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (configSelfOnBoard) {
        this.checkPackageChange().then(res => {
          console.log('checkPackageChange :: ', res)
          if (this.$route.query.isWelcomeBack) {
            if (
              this.compareCurrentDate(localStorage.getItem('stampDateWelcome'))
            ) {
              this.passExpirePackage(true)
            } else {
              localStorage.setItem('stampDateWelcome', new Date())
              setTimeout(() => {
                this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
                this.welcomeBack.details =
                  this.lbl['selfonboard-welcomeback-details']
                this.welcomeBack.isOpenModal = true
                this.welcomeBack.onCancel = () => {
                  this.welcomeBack.isOpenModal = false
                  this.passExpirePackage(true)
                }
              }, 500)
            }
          } else if (this.$route.query.isChangePackage) {
            if (this.crmPackage) {
              this.packageChanged.title = this.lbl['package-changed']
              this.packageChanged.details =
                'CRM PLUS ' + this.crmPackage.PackageName
              this.packageChanged.lifeTime = this.crmPackage.RemainingDay
              let date = moment
                .unix(this.crmPackage.ConsumerExtendExpireDate)
                .format('DD/MM/YYYY')
              var imgHistoryId
              var imgCurrentId

              if (
                PackageHelper.filterPackageByPremium(
                  this.crmPackage.PackageChange.PreviousPackage.PackageId,
                ) ||
                PackageHelper.filterPackageByBasic(
                  this.crmPackage.PackageChange.PreviousPackage.PackageId,
                )
              ) {
                imgHistoryId =
                  this.crmPackage.PackageChange.PreviousPackage.PackageId.toLowerCase() +
                  '_' +
                  this.crmPackage.PackageChange.PreviousPackage.PackagePlanType.toLowerCase()
              } else {
                imgHistoryId = 'free'
              }
              if (
                PackageHelper.filterPackageByPremium(
                  this.crmPackage.PackageChange.NewPackage.PackageId,
                ) ||
                PackageHelper.filterPackageByBasic(
                  this.crmPackage.PackageChange.NewPackage.PackageId,
                )
              ) {
                imgCurrentId =
                  this.crmPackage.PackageChange.NewPackage.PackageId.toLowerCase() +
                  '_' +
                  this.crmPackage.PackageChange.NewPackage.PackagePlanType.toLowerCase()
              } else {
                imgCurrentId = 'free'
              }
              Account.apiAccountInformation().then(res => {
                Account.apiAccountPermissionChange(res.data)
              })

              this.packageChanged.payment = date
              this.packageChanged.imgHistory =
                Vue.bzbsConfig.bzbsBlobUrl +
                `/config/crmplus/package/logo_${imgHistoryId}.jpg` +
                `?v=` +
                new Date().getTime()
              this.packageChanged.imgCurrent =
                Vue.bzbsConfig.bzbsBlobUrl +
                `/config/crmplus/package/logo_${imgCurrentId}.jpg` +
                `?v=` +
                new Date().getTime()
              this.packageChanged.isOpenModal = true
              this.packageChanged.onView = () => {
                this.packageChanged.isOpenModal = false
                if (this.crmPackage) {
                  if (
                    PackageHelper.filterPackageByPremium(
                      this.crmPackage.PackageId,
                    )
                  ) {
                    this.$router.push('/View/Package/Premium')
                  } else {
                    this.$router.push('/View/Package/Basic')
                  }
                } else {
                  this.$router.push('/View/Package/Basic')
                }
              }
              this.packageChanged.onCancel = () => {
                this.apiAcknowledgePackageChange()
                this.packageChanged.isOpenModal = false
                this.passExpirePackage(true)
              }
            } else {
              this.passExpirePackage(true)
            }
          } else {
            if (this.crmPackage) {
              if (
                this.crmPackage.RequirePackage &&
                this.crmPackage.HasSelectedPackage
              ) {
                this.isPackage = true

                if (
                  this.crmPackage.RemainingDay <= 7 &&
                  this.crmPackage.IsFreePackage
                ) {
                  let payment = this.lbl['selfonboard-continuous-payment']
                  let close =
                    this.lbl['selfonboard-line-consumer-close-down-after']
                  let date = moment
                    .unix(this.crmPackage.ExpireDate)
                    .format('DD/MM/YYYY')
                  let line =
                    close +
                    ' ' +
                    date +
                    ' - ' +
                    '<span style="color: red">' +
                    this.crmPackage.RemainingDay +
                    ' ' +
                    this.lbl['selfonboard-day-left'] +
                    '</span>'
                  let details = payment + '<br /><br />' + line

                  if (this.crmPackage.IsFreePackage) {
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm =
                        this.lbl['selfonboard-buy-now']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.$router.push({ name: 'Package' })
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    this.passExpirePackage(true)
                  }
                } else if (
                  this.crmPackage.ExtendRemainingDay <= 7 &&
                  !this.crmPackage.IsStandardPackage
                ) {
                  if (this.crmPackage.IsCorporatePackage) {
                    // ไม่มีปุ่มซื้อทันที
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.showbackto = false
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm = null
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm = this.lbl['selfonboard-renew']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.handleViewPaymentDetails()
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  }
                } else if (
                  this.crmPackage.ExtendRemainingDay <= 7 &&
                  this.crmPackage.IsStandardPackage
                ) {
                  if (this.crmPackage.IsCancelPackage) {
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm =
                        this.lbl['selfonboard-buy-now']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.gotoSelectPackage()
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    if (this.crmPackage.IsCorporatePackage) {
                      // ไม่มีปุ่มซื้อทันที
                      let payment = this.lbl['selfonboard-continuous-payment']
                      let close =
                        this.lbl['selfonboard-line-consumer-close-down-after']
                      let date = moment
                        .unix(this.crmPackage.ExtendExpireDate)
                        .format('DD/MM/YYYY')
                      let line =
                        close +
                        ' ' +
                        date +
                        ' - ' +
                        '<span style="color: red">' +
                        this.crmPackage.ExtendRemainingDay +
                        ' ' +
                        this.lbl['selfonboard-day-left'] +
                        '</span>'
                      let details = payment + '<br /><br />' + line
                      setTimeout(() => {
                        this.expirePackage.title =
                          this.lbl['selfonboard-package-is-ending']
                        this.expirePackage.details = details
                        this.expirePackage.modalType = 'warning'
                        this.expirePackage.isOpenModal = true
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.showbackto = false
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.confirm = null
                        this.expirePackage.onConfirm = () => {
                          this.expirePackage.isOpenModal = false
                        }
                        this.expirePackage.onCancel = () => {
                          this.expirePackage.isOpenModal = false
                          this.passExpirePackage(true)
                        }
                      }, 500)
                    } else {
                      let payment = this.lbl['selfonboard-continuous-payment']
                      let close =
                        this.lbl['selfonboard-line-consumer-close-down-after']
                      let date = moment
                        .unix(this.crmPackage.ExtendExpireDate)
                        .format('DD/MM/YYYY')
                      let line =
                        close +
                        ' ' +
                        date +
                        ' - ' +
                        '<span style="color: red">' +
                        this.crmPackage.ExtendRemainingDay +
                        ' ' +
                        this.lbl['selfonboard-day-left'] +
                        '</span>'
                      let details = payment + '<br /><br />' + line
                      setTimeout(() => {
                        this.expirePackage.title =
                          this.lbl['selfonboard-package-is-ending']
                        this.expirePackage.details = details
                        this.expirePackage.modalType = 'warning'
                        this.expirePackage.isOpenModal = true
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.confirm =
                          this.lbl['selfonboard-renew']
                        this.expirePackage.onConfirm = () => {
                          this.expirePackage.isOpenModal = false
                          this.handleViewPaymentDetails()
                        }
                        this.expirePackage.onCancel = () => {
                          this.expirePackage.isOpenModal = false
                          this.passExpirePackage(true)
                        }
                      }, 500)
                    }
                  }
                } else {
                  this.passExpirePackage(true)
                }
              } else {
                this.passExpirePackage(true)
              }
            } else {
              this.passExpirePackage(true)
            }
          }
        })
      } else {
        this.passExpirePackage(true)
      }
    },
    handleViewPaymentDetails() {
      console.log('handleViewPaymentDetails')
      if (this.subscriptionId) {
        var params = {
          return_url: '/Account/Package?shoppingCart=true',
          success_url: '/Account/Package?shoppingCart=true',
          IsStandardPackage: this.crmPackage.IsStandardPackage
            ? this.crmPackage.IsStandardPackage
            : false,
        }
        BzbsCart.goShopingCartOrder(params, this.subscriptionId)
      } else {
        this.gotoSelectPackage()
      }
    },
    gotoSelectPackage() {
      this.$router.push({ name: 'Package' })
    },
    goShopping(packageId) {
      this.handleLoading(true)
      var arrData = [{ id: packageId, qty: 1 }]
      return new Promise(resolve => {
        BzbsCart.apiAddtoCart(packageId, arrData)
          .then(res => {
            console.log('apiAddtoCart :: ', res.data)
            this.goShoppingCart()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log(error)
            this.$emit('error', error.response.data.error.message)
            resolve(error)
          })
      })
    },
    goShoppingCart() {
      var params = {
        return_url: '/Account/Package?shoppingCart=true',
        success_url: '/Account/Package?shoppingCart=true',
        IsStandardPackage: this.crmPackage.IsStandardPackage ? true : false,
      }
      BzbsCart.goShopingCart(params)
    },
    checkPackageChange() {
      return new Promise(resolve => {
        this.getNonStandardPackage().then(() => {
          if (this.crmPackage) {
            if (
              this.crmPackage.PackageChange !== null &&
              this.crmPackage.PackageChange.IsPackageChangeAcknowledged == false
            ) {
              const currentId =
                this.crmPackage.PackageChange.PreviousPackage.PackageId.toLowerCase()

              if (this.nonStandardPackageList.includes(currentId)) {
                setTimeout(() => {
                  this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
                  this.welcomeBack.details =
                    this.lbl['selfonboard-welcomeback-details']
                  this.welcomeBack.isOpenModal = true
                  this.welcomeBack.onCancel = () => {
                    localStorage.setItem('stampDateWelcome', new Date())
                    this.apiAcknowledgePackageChange()

                    this.welcomeBack.isOpenModal = false
                    this.passExpirePackage(true)
                  }
                }, 500)
              } else {
                if (this.$route.query.changepackage) {
                  this.$router.replace({
                    path: this.$router.path,
                    query: {
                      changepackage: true,
                      isChangePackage: 'true',
                      timestamp: Date.now(),
                    },
                  })
                } else {
                  this.$router.replace({
                    path: this.$router.path,
                    query: {
                      isChangePackage: 'true',
                      timestamp: Date.now(),
                    },
                  })
                }
              }
              resolve(true)
            } else {
              this.passExpirePackage(true)
              resolve(false)
            }
          }
        })
      })
    },
    getNonStandardPackage() {
      return new Promise(resolve => {
        BzbsPackage.apiGetNonStandardPackage()
          .then(res => {
            var packageData = res.data
            this.nonStandardPackageList = packageData.map(item =>
              item.PackageId.toLowerCase(),
            )
            resolve(res.data)
          })
          .catch(error => {
            console.log(error)
            resolve(error)
          })
      })
    },
    apiAcknowledgePackageChange() {
      return new Promise((resolve, reject) => {
        BzbsPackage.apiAcknowledgePackageChange()
          .then(res => {
            this.$router.replace({
              path: this.$router.path,
            })
            this.$router.go(0)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    //#region Main
    getTotalPdpaUploadLog(type) {
      return new Promise(resolve => {
        BzbsPdpa.getImportConsentLog(type, 10, 0)
          .then(res => {
            resolve(res.data.total)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    getDateList() {
      const today = new Date()
      this.selectDate = [
        {
          id: 0,
          name: 'Today',
          start: moment(today).format('ll'),
          end: moment(today).format('ll'),
        },
        {
          id: 1,
          name: 'Yesterday',
          start: moment(today).add(-1, 'd').format('ll'),
          end: moment(today).add(-1, 'd').format('ll'),
        },
        {
          id: 2,
          name: 'Last 7 Days',
          start: moment(today).add(-6, 'd').format('ll'),
          end: moment(today).format('ll'),
        },
        {
          id: 3,
          name: 'Last 30 Days',
          start: moment(today).add(-29, 'd').format('ll'),
          end: moment(today).format('ll'),
        },
        {
          id: 4,
          name: 'This Month',
          start: moment(today).startOf('month').format('ll'),
          end: moment(today).endOf('month').format('ll'),
        },
        {
          id: 5,
          name: 'Last Month',
          start: moment(today).add(-1, 'M').startOf('month').format('ll'),
          end: moment(today).add(-1, 'M').endOf('month').format('ll'),
        },
        {
          id: 6,
          name: 'Custom Range',
        },
      ]
      this.textStartDate = moment(today).add(-29, 'd').format('ll')
      this.textEndDate = moment(today).format('ll')
      let dataDate = {
        startdate: moment(today).add(-29, 'd').format('YYYY-MM-DD'),
        enddate: moment(today).format('YYYY-MM-DD'),
      }
      this.dataChart = dataDate
      this.dataDateInsight = dataDate
    },
    onChangePicker(date) {
      this.countLoading = 0
      var dataDate = {
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
      }
      this.dataChart = dataDate
      this.dataDateInsight = dataDate
      console.log('dataChart : ', this.dataChart)
      this.$forceUpdate()
    },
    changeSelectDate(e) {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_fillter',
        'on click',
      )
      switch (e) {
        case 0:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_today',
            'on click',
          )
          break
        case 1:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_yesterday',
            'on click',
          )
          break
        case 2:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_last_seven_day',
            'on click',
          )
          break
        case 3:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_last_thirty_day',
            'on click',
          )
          break
        case 4:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_this_month',
            'on click',
          )
          break
        case 5:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_last_month',
            'on click',
          )
          break
        case 6:
          Account.bzbsAnalyticTracking(
            'home_page',
            'dashboard_details',
            'click_fillter_custom',
            'on click',
          )
          break
      }
      this.countLoading = 0
      if (e == 6) {
        this.isSelectDate = true
      } else {
        this.isSelectDate = false
        this.textStartDate = this.selectDate[e].start
        this.textEndDate = this.selectDate[e].end
        let dataDate = {
          startdate: moment(new Date(this.textStartDate)).format('YYYY-MM-DD'),
          enddate: moment(new Date(this.textEndDate)).format('YYYY-MM-DD'),
        }
        this.dataChart = dataDate
        this.dataDateInsight = dataDate
      }
    },
    handleSeemore() {
      this.isSeemore = true
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_see_more',
        'on click',
      )
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'view_dashboard_details',
        'on view',
      )
    },
    //#endregion Main

    //#region Insight
    handleInsights() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_insights',
        'on click',
      )
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'view_insights',
        'on view',
      )
      if (this.crmPackage && this.crmPackage.IsFreePackage) {
        this.showAlertUpgradepackage = true
        this.actionUpgrade = {
          internalPage: true,
          pageName: 'Package',
        }
      } else {
        this.showModalInsights = true
        this.dataInsights = {
          startdate: this.dataDateInsight.startdate,
          enddate: this.dataDateInsight.enddate,
        }
      }
    },
    ChangeInsights(res) {
      this.showModalInsights = res
    },
    //#endregion Insight

    //#region Transactions
    handleTransactions() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions',
        'on click',
      )
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'view_transactions',
        'on view',
      )
      this.showModalTransactions = true
      this.dataTransactions = {
        startdate: this.dataDateInsight.startdate,
        enddate: this.dataDateInsight.enddate,
      }
    },
    ChangeTransactions(res) {
      this.showModalTransactions = res
    },
    ChangeLoadingBox(res) {
      this.countLoadingBox += res

      if (this.countLoadingBox >= 5) {
        this.handleLoading(false)
      } else {
        this.handleLoading(true)
      }
    },
    ChangeLoading(res) {
      this.countLoading += res
      this.handleLoading(false)
    },
    //#endregion Transactions

    //#region Package
    passExpirePackage(res) {
      if (res) {
        var loginType =
          Account.getCacheProfileAddress().strLoginType.toLowerCase()
        if (!Vue.bzbsConfig.businessRule.webFor.PH && loginType == 'line') {
          this.checkSetupLineConnect()
        } else {
          this.passNotify(true)
        }
      }
    },
    //#endregion Package

    //#region incomplete account setting
    checkSetupLineConnect() {
      if (Account.getCacheProfileAddress().strLineLoginChannelId) {
        if (this.compareCurrentDate(localStorage.getItem('stampDateWelcome'))) {
          this.passNotify(true)
        } else {
          setTimeout(() => {
            this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
            this.welcomeBack.details =
              this.lbl['selfonboard-welcomeback-details']
            this.welcomeBack.isOpenModal = true
            this.welcomeBack.onCancel = () => {
              this.welcomeBack.isOpenModal = false
              localStorage.setItem('stampDateWelcome', new Date())
              this.passNotify(true)
            }
          }, 500)
        }
      } else {
        this.openNotificationAccountSetting()
      }
    },
    openNotificationAccountSetting() {
      setTimeout(() => {
        this.notificationAccountSetting.title =
          this.lbl['privacy-notification-line-setup-title']
        this.notificationAccountSetting.details =
          this.lbl['privacy-notification-line-setup-detail']
        this.notificationAccountSetting.modalType = 'warning'
        this.notificationAccountSetting.isOpenModal = true
        this.notificationAccountSetting.backto =
          this.lbl['confirm-box-cancel-button']
        this.notificationAccountSetting.confirm =
          this.lbl['privacy-notification-upload-btn-setup']
        this.notificationAccountSetting.onConfirm = () => {
          this.notificationAccountSetting.isOpenModal = false
          this.$router.push({ name: 'ConnectSetting' })
        }
        this.notificationAccountSetting.onCancel = () => {
          if (
            this.compareCurrentDate(localStorage.getItem('stampDateWelcome'))
          ) {
            this.passNotify(true)
          } else {
            localStorage.setItem('stampDateWelcome', new Date())
            setTimeout(() => {
              this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
              this.welcomeBack.details =
                this.lbl['selfonboard-welcomeback-details']
              this.welcomeBack.isOpenModal = true
              this.welcomeBack.onCancel = () => {
                this.welcomeBack.isOpenModal = false
                this.passNotify(true)
              }
            }, 500)
          }
          this.notificationAccountSetting.isOpenModal = false
        }
      }, 500)
    },
    //endregion incomplete account setting

    //#region Announcement
    passNotify(res) {
      if (this.profileConfig.ShownAnnouncement) {
        if (res) {
          if (
            this.compareCurrentDate(
              localStorage.getItem('stampDateAnnouncement'),
            )
          ) {
            this.passAnnouncement(true)
          } else {
            localStorage.setItem('stampDateAnnouncement', new Date())
            setTimeout(() => {
              this.callAnnouncement()
            }, 250)
          }
        }
      } else {
        this.passAnnouncement(true)
      }
    },
    callAnnouncement() {
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiAnnouncements(this.skip, this.top, null, true, null)
          .then(res => {
            if (res.data.data.length > 0) {
              this.announcement.data = res.data.data
              this.announcement.onCancel = () => {
                this.onCancelAnnouncement(true)
                this.announcement.isOpenModal = false
              }
              this.announcement.isOpenModal = true
            } else {
              this.passAnnouncement(true)
            }
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    onCancelAnnouncement(res) {
      console.log('onCancelAnnouncement : ', res)
      this.passAnnouncement(true)
    },
    passAnnouncement(res) {
      console.log('passAnnouncement : ', res)
    },
    //#endregion Announcement
  },
}
</script>

<style lang="scss">
@import '@/style/dashboard.scss';
.alert-package {
  border: 2px solid #ff5252;
  padding: 4px 40px;
  color: #ff5252;
  border-radius: 4px;
}
</style>
